import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import { Save, XCircle } from "react-feather";
import { useForm } from "react-hook-form";
import {PGET, PUT} from "../../../api/adminApi";
import Select from "react-select";

export default function UniversalModal({
                                         modal: { show, masterId, text, url },
                                         setModal,
                                         getItems,
                                     }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
    } = useForm();
    function onHide() {
        setModal({ show: false, masterId: null });
        clearErrors();
    }
    async function assign() {
        const res = await PUT(`/services/admin/api/${url}/${masterId}`, {id: masterId})
        if (res && res.success === true) {
            getItems();
            onHide();
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {text}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(assign)} autoComplete="off">
                    <div className="d-flex justify-content-end gap-4">
                        <button
                            type="button"
                            onClick={onHide}
                            className="btn btn-outline-purple d-flex align-items-center"
                        >
                            <XCircle size={14} className="mr-2"/>
                            Отмена
                        </button>

                        <button
                            type="submit"
                            className="btn btn-purple d-flex align-items-center"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить{" "}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
