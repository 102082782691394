import React, {useEffect, useState} from "react";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {Download, Search, Users} from "react-feather";
import Select from "react-select";

import {excelParams, GET, PGET} from "../../../api/adminApi";
import CodeAssign from "./CodeAssign";
import CodeUnassign from "./CodeUnassign";
import Bonused from "./Bonused";
import Unbonused from "./Unbonused";
import UniversalModal from "./UniversalModal";

const requestOptions = [
    {
        id: 0,
        name: "Все"
    },
    {
        id: 1,
        name: "Бонусный"
    },
    {
        id: 2,
        name: "Не Бонусный"
    }
]

function Code() {
    const [items, setItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(+localStorage.getItem("currentPageCodes") || 0); //for numeration and saving currentPage after refresh
    const [soldOptions] = useState([{value: 0, label: "Все"}, {value: 1, label: "Продан"}, {
        value: 2,
        label: "Не продан"
    },]);
    const [soldValue, setSoldValue] = useState(soldOptions[0]);
    const [mountedOptions] = useState([{value: 0, label: "Все"}, {value: 1, label: "Установлен"}, {
        value: 2,
        label: "Не установлен"
    },]);
    const [mountedValue, setMountedValue] = useState(mountedOptions[0]);
    const [assignedOptions] = useState([{value: 0, label: "Все"}, {value: 1, label: "Привязан"}, {
        value: 2,
        label: "Не привязан"
    },]);
    const [assignedValue, setAssignedValue] = useState(assignedOptions[0]);
    const [modal, setModal] = useState({show: false, masterId: null});
    const [productBrands, setProductBrands] = useState([{id: 0, name: "Все"}]);
    const [productBrand, setProductBrand] = useState(productBrands[0]);

    const [productHelpers, setProductHelpers] = useState([{id: 0, name: "Все"}]);
    const [productHelper, setProductHelper] = useState(productHelpers[0]);

    const [modalUnassign, setModalUnassign] = useState({show: false, masterId: null})
    const [modalBonus, setModalBonus] = useState({show: false, masterId: null})
    const [modalUnBonus, setModalUnBonus] = useState({show: false, masterId: null})
    const [universalModal, setUniversalModal] = useState({show: false, masterId: null, url: '', text: ''})
    const [soldModal, setSoldModal] = useState({show: false, masterId: null})

    const [requestOpt, setRequestOpt] = useState(requestOptions[0]);
    const selectRequestBy = (selectedOpt) => {
        if (requestOpt !== selectedOpt) {
            setRequestOpt(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const selectSoldFilter = (selectedOpt) => {
        if (selectedOpt !== soldValue) {
            setSoldValue(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const selectBrandFilter = (selectedOpt) => {
        if (selectedOpt !== productBrand) {
            setProductBrand(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const selectProductHelperFilter = (selectedOpt) => {
        if (selectedOpt !== productHelper) {
            setProductHelper(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const selectMountedFilter = (selectedOpt) => {
        if (selectedOpt !== mountedValue) {
            setMountedValue(selectedOpt);
            setCurrentPageToZero();
        }
    };
    const selectAssignedFilter = (selectedOpt) => {
        if (selectedOpt !== assignedValue) {
            setAssignedValue(selectedOpt);
            setCurrentPageToZero();
        }
    };

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageCodes", 0);
    }

    function paginate(data) {
        setCurrentPage(data.selected);
        localStorage.setItem("currentPageCodes", data.selected); //for saving currentPage after refresh
    }

    async function getItems() {
        const data = await PGET("/services/admin/api/codes-pageList", true, {
            sold: soldValue.value,
            mounted: mountedValue.value,
            assigned: assignedValue.value,
            page: currentPage,
            size: 10,
            search: value,
            brandId: productBrand.id,
            productId: productHelper.id,
            bonused: requestOpt.id
        });
        setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
        setItems(data.data);
    }

    async function getProductBrands() {
        const response = await GET(`/services/admin/api/product-brand-helper`);
        if (response.length > 0) {
            setProductBrands([productBrands[0], ...response]);
        }
    }

    async function getProductHelper() {
        const response = await GET(`/services/admin/api/product-helper/${productBrand.id}`);
        if (response.length > 0) {
            setProductHelpers([productHelpers[0], ...response]);
        }
    }

    function codesExcel() {
        const excelUrl = 'https://bo.airfelsales.uz/services/admin/api/codes/codes-all/excel';
        const fileName = 'codes-all.xlsx'
        excelParams(excelUrl, fileName, true, {
            sold: soldValue.value,
            mounted: mountedValue.value,
            assigned: assignedValue.value,
            page: currentPage,
            size: 10,
            search: value,
        });
    }

    useEffect(() => {
        getItems();
    }, [soldValue, mountedValue, assignedValue, currentPage, value, productBrand, productHelper, requestOpt]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getProductBrands()
    }, []);

    useEffect(() => {
        getProductHelper()
    }, [productBrand]);

    return (<div style={{background: "#eef1ff", marginTop: "-0.9rem"}}>
        <div className="fz18 border-bottom pl-3 py-3 my-2 d-flex align-items-center">
            <Users className="mr-2" color="#5C3EBA"/> <span>Коды</span>
        </div>

        <div className="px-3 pb-4 pt-2">
            <div className="mb-4 d-flex justify-content-between">
                <div className="d-flex gap-2">
                    <div style={{width: "120px"}}>
                        <Select
                            value={soldValue}
                            placeholder="Выберите"
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            options={soldOptions}
                            onChange={selectSoldFilter}
                        />
                    </div>
                    <div style={{width: "120px"}}>
                        <Select
                            value={mountedValue}
                            placeholder="Выберите"
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            options={mountedOptions}
                            onChange={selectMountedFilter}
                        />
                    </div>

                    <div style={{width: "120px"}}>
                        <Select
                            value={assignedValue}
                            placeholder="Выберите"
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            options={assignedOptions}
                            onChange={selectAssignedFilter}
                        />
                    </div>

                    <div style={{width: "140px"}}>
                        <Select
                            value={productBrand}
                            placeholder="Выберите"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            options={productBrands}
                            onChange={selectBrandFilter}
                        />
                    </div>

                    <div style={{width: "140px"}}>
                        <Select
                            value={productHelper}
                            placeholder="Выберите"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            options={productHelpers}
                            onChange={selectProductHelperFilter}
                        />
                    </div>
                    <div style={{width: "140px"}}>
                        <Select
                            value={requestOpt}
                            placeholder="Выберите"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            options={requestOptions}
                            onChange={selectRequestBy}
                        />
                    </div>
                    <div className="position-relative">
                        <Search
                            size={14}
                            color="#9D9BA3"
                            className="input-search-icon"
                        />
                        <DebounceInput
                            minLength={3}
                            debounceTimeout={400}
                            value={value}
                            onChange={handleSearchInput}
                            type="text"
                            className="input-search box-shadow br-5"
                            size={24}
                            placeholder="Поиск"
                        />
                    </div>
                </div>
                <div className="d-flex">
                    <button
                        onClick={codesExcel}
                        className="btn btn-table mr-0 box-shadow"
                        style={{backgroundColor: "#F4F4F5", color: "#000", fontSize: 14}}
                    >
                        <Download color={"#E63950"} size={16} className="mr-2"/>
                        Export to excel
                    </button>
                </div>
            </div>
            <div style={{overflowX: "auto"}}>
                <table className="table background-white rounded box-shadow br-5">
                    <thead>
                    <tr className="backgroung-grey">
                        <th style={{width: "30px"}} className="text-center">
                            №
                        </th>
                        <th className="text-start" style={{width: "15%"}}>Код</th>
                        <th className="text-start" style={{width: "15%"}}>Продукт</th>
                        <th className="text-start">Приход</th>
                        <th className="text-start">Клиент</th>
                        <th className="text-start">Продан</th>
                        <th className="text-start">Установлен</th>
                        <th className="text-start">Привязан</th>
                        <th className="text-start">Бонус</th>
                        <th className="text-start">Бонусный</th>
                        <th className="text-center"></th>
                    </tr>
                    </thead>

                    <tbody style={{overflowX: "auto"}}>
                    {items?.map((item, idx) => {
                        console.log(item)
                        return (<tr key={item.id}>
                            <td className="text-center">
                                {currentPage === 0 ? idx + 1 : currentPage * 10 + idx + 1}
                            </td>
                            <td className="text-start">
                                <div className='d-flex flex-column w-fit-content'>
                                    <span>{`Внешний код: ${item.externalCode || ''}`}</span>
                                    <span>{`Скретч код: ${item.scratchCode || ''}`}</span>
                                    <span>{`Серийный номер: ${item.serialNumber || ''}`}</span>
                                </div>
                            </td>
                            <td className="text-start">
                                <div className='d-flex flex-column'>
                                    <span>{`Бренд: ${item.productBrandName || ''}`}</span>
                                    <span>{`Модель: ${item.productName || ''}`}</span>
                                    <span>{`Объект: ${item.productAssignedToObject ? 'Да' : 'Нет'}`}</span>
                                </div>
                            </td>
                            <td className="text-start">
                                <div className='d-flex flex-column'>
                                    <span>{`Тир: ${item.tir || ''}`}</span>
                                    <span>{`Время:  ${item.receivedTime?.slice(0, 10) || ''}`}</span>
                                </div>
                            </td>
                            <td className="text-start">
                                <div className='d-flex flex-column'>
                                    <span>{`Имя: ${item.ownerName || ''}`}</span>
                                    <span>{`Номер: ${item.ownerPhone || ''}`}</span>
                                    <span>{`Время регистрации: ${item.OwnerRegisteredTime ? new Date(item.OwnerRegisteredTime).toLocaleString() : ''}`}</span>
                                </div>
                            </td>
                            <td className="text-start">
                                {item.sold ? (<div>
                                    <div>
                                        <b>Время: </b>
                                        {item.soldTime?.slice(0, 10)};{" "}
                                        {item.soldTime?.slice(11, 19)}
                                    </div>
                                    <div>
                                        <b>Контрагент: </b>
                                        {item.contragentName}
                                    </div>
                                    <div>
                                        <b>Номер: </b>
                                        {item.contragentPhone}
                                    </div>
                                    <div>
                                        <b>Номер запроса: </b>
                                        {item.soldRequestId}
                                    </div>
                                </div>) : ("Нет")}
                            </td>
                            <td className="text-start">
                                {item.mounted ? (<div>
                                    <div>
                                        <b>Время: </b>
                                        {item.mountedTime?.slice(0, 10)};{" "}
                                        {item.mountedTime?.slice(11, 19)}
                                    </div>
                                    <div>
                                        <b>Мастер: </b>
                                        {item.masterName}
                                    </div>
                                    <div>
                                        <b>Номер: </b>
                                        {item.masterPhone}
                                    </div>
                                    <div>
                                        <b>Номер запроса: </b>
                                        {item.mountedRequestId}
                                    </div>
                                </div>) : ("Нет")}
                            </td>
                            <td className="text-start">
                                {item.assignedToObject ? (<div>
                                    Да
                                    <div>
                                        <b>Объект: </b>
                                        {item.objectName}
                                    </div>
                                    <div>
                                        <b>Менеджер: </b>
                                        {item.managerName}
                                    </div>
                                </div>) : ("Нет")}
                            </td>
                            <td className="text-start">
                                <div className='d-flex flex-column w-fit-content'>
                                    <span>{`Бонус: ${item.bonused ? ("Да") : ("Нет") || ''}`}</span>
                                    <span>{`Н-ие: ${item.bonusName || '-'}`}</span>
                                </div>
                            </td>
                            <td>
                                {item.bonused ? (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setModalUnBonus({
                                                show: true, masterId: item.id,
                                            });
                                        }}
                                        className="box-shadow br-5 btn btn-danger btn-sm"
                                    >
                                        Не бонус
                                    </button>
                                ) : (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setModalBonus({
                                                show: true, masterId: item.id,
                                            });
                                        }}
                                        className="box-shadow br-5 btn btn-dark btn-sm"
                                    >
                                        Бонус
                                    </button>)}
                                <div className="mt-1">
                                    {item.mounted ? (
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setUniversalModal({
                                                    show: true, masterId: item.id, url: 'codes-unmounted', text: 'Вы действительно хотите сделать не установлен этот товар?'
                                                });
                                            }}
                                            className="box-shadow br-5 btn btn-danger btn-sm"
                                        >
                                            Не установлен
                                        </button>
                                    ) : (
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setUniversalModal({
                                                    show: true, masterId: item.id, url: 'codes-mounted', text: 'Вы действительно хотите сделать установлен этот товар?'
                                                });
                                            }}
                                            className="box-shadow br-5 btn btn-success btn-sm"
                                        >
                                            Установлен
                                        </button>)}
                                </div>
                            </td>
                            <td className="text-start">
                                {item.assignedToObject ? (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setModalUnassign({
                                                show: true, masterId: item.id,
                                            });
                                        }}
                                        className="box-shadow br-5 btn btn-info btn-sm"
                                    >
                                        Отвязать
                                    </button>
                                ) : (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setModal({
                                                show: true, masterId: item.id,
                                            });
                                        }}
                                        className="box-shadow br-5 btn btn-primary btn-sm"
                                    >
                                        Привязать
                                    </button>)}
                                <div className="mt-1">
                                    {item.sold ? (
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setUniversalModal({
                                                    show: true, masterId: item.id, url: 'codes-unsold', text: 'Вы действительно хотите сделать не продан этот товар?'
                                                });
                                            }}
                                            className="box-shadow br-5 btn btn-warning btn-sm"
                                        >
                                            Не продан
                                        </button>
                                    ) : (
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setUniversalModal({
                                                    show: true, masterId: item.id, url: 'codes-sold', text: 'Вы действительно хотите сделать продан этот товар?'
                                                });
                                            }}
                                            className="box-shadow br-5 btn btn-success btn-sm"
                                        >
                                            Продан
                                        </button>)}
                                </div>
                            </td>
                        </tr>);
                    })}
                    </tbody>
                </table>
            </div>
            {pageCount > 1 && (<ReactPaginate
                forcePage={currentPage}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                onPageChange={paginate}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageClassName={"page-item box-shadow"}
            />)}
        </div>
        <CodeUnassign modal={modalUnassign} setModal={setModalUnassign} getItems={getItems}/>
        <Unbonused modal={modalUnBonus} setModal={setModalUnBonus} getItems={getItems}/>
        <Bonused modal={modalBonus} setModal={setModalBonus} getItems={getItems}/>
        <CodeAssign modal={modal} setModal={setModal} getItems={getItems}/>
        <UniversalModal modal={universalModal} setModal={setUniversalModal} getItems={getItems} />
    </div>);
}

export default Code;
